import React from 'react'
import "./WhatsappButton.scss"
import iconImg from "./../images/whatsapp.png"

const WhatsappButton = (props) => {
  let title = "Chatea en WhatsApp"

  if (props.lang === 'en'){
    title = 'Chat on Whatsapp'
  }

  return (
    <a className='whatsappBtn' href='https://api.whatsapp.com/send?phone=34680983371&text=Hola!%20Quiero%20conversar%20con%20un%20experto%20de%20Virtual%20Ed%20Global%20sobre' target="_blank" rel="noreferrer">
      <img src={iconImg} width="25" alt="" />
      <div>{title}</div>
    </a>
  )
}

export default WhatsappButton