import React from "react";
import "./BlogCard.scss"
import { Link } from "gatsby"

const BlogCard = ({title, description, category, link}) => {
  let categoryColor = ''
  if (category === 'Blog Post') { categoryColor = 'cat-color-1' }
  if (category === 'Ebook') { categoryColor = 'cat-color-2' }
  if (category === 'Webinar') { categoryColor = 'cat-color-3' }

  return (
    <>
      <Link to={link} className="blog-card">
        <div className={`cat-name ${categoryColor}`}>{category}</div>
        <h3>{title}</h3>
        <p>{description}</p>
        <button className="btn btn-dark">Leer más</button>
      </Link>
    </>
  )
}

export default BlogCard