import React, { useState, useEffect } from "react";
import Navbar from "../components/Navbar";
import { Helmet } from "react-helmet";
import { Link, graphql, useStaticQuery } from "gatsby";
import "../scss/main.scss";
import BlogCard from "../components/BlogCard";
import Footer from "../components/Footer";
import WhatsappButton from "../components/WhatsappButton";
/* import { GatsbyImage, getImage } from 'gatsby-plugin-image' */
import "../scss/recursos.scss";
import 'bootstrap/dist/css/bootstrap.min.css';

const RecursosPage = () => {
  const [selectedCategory, setSelectedCategory] = useState("All");
  const [filteredPosts, setFilteredPosts] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState();
  const [msgNoResultsActivated, setMsgNoResultsActivated] = useState(false);

  const data = useStaticQuery(graphql`
    query {
      allContentfulResourcePost(sort: { fields: datePublished, order: DESC }) {
        edges {
          node {
            id
            title
            slug
            contentful_id
            hideFromResourceList
            datePublished(formatString: "MMMM Do, YYYY")
            subtitle {
              subtitle
            }
            category {
              categoryName
              categoryId
            }
          }
        }
      }
      allContentfulResourceCategory {
        nodes {
          categoryName
          categoryId
        }
      }
      allContentfulResourcePageTemplate {
        nodes {
          featurePostBox1 {
            title
            contentful_id
            slug
            category {
              categoryName
              categoryId
            }
            image {
              file {
                url
              }
              gatsbyImageData
            }
          }
          featurePostBox2 {
            title
            contentful_id
            slug
            category {
              categoryName
              categoryId
            }
            image {
              file {
                url
              }
              gatsbyImageData
            }
          }
          featurePostBox3 {
            title
            contentful_id
            slug
            category {
              categoryName
              categoryId
            }
            image {
              file {
                url
              }
              gatsbyImageData
            }
          }
        }
      }
    }
  `);

  useEffect(() => {
    if (selectedCategory !== "All") {
      return setFilteredPosts([
        ...data.allContentfulResourcePost.edges.filter((cat) => cat.node.category.categoryName === selectedCategory),
      ]);
    }
    setFilteredPosts(
      [...data.allContentfulResourcePost.edges].filter((item) => {
        return !item.node.hideFromResourceList;
      })
    );
  }, [selectedCategory, data.allContentfulResourcePost.edges]);

  const pageTitle = "Recursos | Virtual Ed Global"
  const pageDescription = "Conocé más acerca de nuestros recursos: Ebooks, Webinars, Blogposts"

  useEffect(() => {
    // TODO: Don´t repeat this code similar to the one to filter before mapping posts. Here is currently just checking if there are results
    // to show "No results" instead.
    let results = filteredPosts.filter((postItem) => {
      if (searchQuery === "") {
        return postItem;
      } else if (postItem.node.title.toLowerCase().includes(searchQuery.toLowerCase())) {
        return postItem;
      }
      return null;
    });
    setSearchResults([...results]);
  }, [searchQuery, filteredPosts]);

  function handleSearch(e) {
    setSearchQuery(e.target.value);
    setMsgNoResultsActivated(true);
  }

  return (
    <main>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <meta property="og:title" content={pageTitle} />
        <meta property="og:site_name" content="Virtual Ed Global" />
        <meta property="og:url" content="https://virtualedglobal.com/recursos" />
        <meta property="og:description" content={pageDescription} />
        <meta property="og:type" content="website" />
        {/* <meta property="og:image" content={domain + FbImg} />
        <meta name="twitter:image" content={domain + TwitterImg} /> */}
        <meta name="twitter:title" content={pageTitle} />
        <meta name="twitter:description" content={pageDescription} /> 
      </Helmet>

      <Navbar />

      <div className="page-wrapper">
        <section>
          <div className="container">
            <h1 className="title-no-img">Recursos</h1>

            <div className="featured-posts">
              {data.allContentfulResourcePageTemplate.nodes.map((item) => {
                return (
                  <Link
                    to={`/blog/${item.featurePostBox1.slug}`}
                    className="featured-box a"
                    key={item.featurePostBox1.contentful_id}
                    style={{ backgroundImage: "url(" + item.featurePostBox1.image.file.url + ")" }}
                  >
                    <div className="featured-box-inner-wrapper">
                      <span className="featured-box-category">{item.featurePostBox1.category.categoryName}</span>
                      <h3 className="featured-box-title">{item.featurePostBox1.title}</h3>
                      <button className="featured-box-button">Leer más</button>
                    </div>
                  </Link>
                );
              })}

              {data.allContentfulResourcePageTemplate.nodes.map((item) => {
                return (
                  <Link
                    to={`/blog/${item.featurePostBox2.slug}`}
                    className="featured-box b"
                    key={item.featurePostBox2.contentful_id}
                    style={{ backgroundImage: "url(" + item.featurePostBox2.image.file.url + ")" }}
                  >
                    <div className="featured-box-inner-wrapper">
                      <span className="featured-box-category">{item.featurePostBox2.category.categoryName}</span>
                      <h3 className="featured-box-title">{item.featurePostBox2.title}</h3>
                      <button className="featured-box-button">Leer más</button>
                    </div>
                  </Link>
                );
              })}

              {data.allContentfulResourcePageTemplate.nodes.map((item) => {
                return (
                  <Link
                    to={`/blog/${item.featurePostBox3.slug}`}
                    className="featured-box c"
                    key={item.featurePostBox3.contentful_id}
                    style={{ backgroundImage: "url(" + item.featurePostBox3.image.file.url + ")" }}
                  >
                    <div className="featured-box-inner-wrapper">
                      <span className="featured-box-category">{item.featurePostBox3.category.categoryName}</span>
                      <h3 className="featured-box-title">{item.featurePostBox3.title}</h3>
                      <button className="featured-box-button">Leer más</button>
                    </div>
                  </Link>
                );
              })}
            </div>

            <div className="searchbar mb-3">
              <input
                type="text"
                name="search-form"
                id="search-input"
                className="search-input"
                placeholder="Buscar..."
                value={searchQuery}
                onChange={(e) => handleSearch(e)}
              />
              {/* Filtros */}
              <div className="filterBtns">
                <button
                  onClick={() => setSelectedCategory("All")}
                  className={selectedCategory === "All" ? "active" : ""}
                >
                  Todos
                </button>
                {data &&
                  data.allContentfulResourceCategory.nodes.map((item) => {
                    return (
                      <button
                        key={item.categoryName}
                        className={item.categoryName === selectedCategory ? "active" : ""}
                        onClick={() => setSelectedCategory(item.categoryName)}
                      >
                        {item.categoryName}
                      </button>
                    );
                  })}
              </div>
            </div>

            {data &&
              filteredPosts
                .filter((postItem) => {
                  if (searchQuery === "") {
                    return postItem;
                  } else if (postItem.node.title.toLowerCase().includes(searchQuery.toLowerCase())) {
                    return postItem;
                  }
                  return null;
                })
                .map((item) => {
                  return (
                    <BlogCard
                      category={item.node.category.categoryName}
                      title={item.node.title}
                      description={item.node.subtitle.subtitle}
                      link={`/blog/${item.node.slug}`}
                      key={item.node.id}
                    />
                  );
                })}
            {(searchResults && !searchResults.length && msgNoResultsActivated) || !filteredPosts.length ? (
              <div className="no-results-container">
                <p>No hay resultados que coincidan con la búsqueda.</p>
              </div>
            ) : (
              <></>
            )}
          </div>
        </section>
      </div>

      <WhatsappButton />
      <Footer />
    </main>
  );
};

export default RecursosPage;
